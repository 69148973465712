import React from "react"
import { Link } from "gatsby"

const NavLogo = ({ size }) => {
  return (
    <Link to="/">
      <span
        className={`py-2 flex bg-clip-text text-transparent bg-gradient-to-r from-teal-300 to-cyan-400 text-${size} font-extralight`}
      >
        The Simple Crypto Fund
      </span>
    </Link>
  )
}
export default NavLogo
