import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const NavBarDropdown = ({ caption, dropdownItems }) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? "text-white" : "text-gray-300",
              "group bg-gray-900 rounded-md inline-flex items-center text-lg font-extralight hover:text-white"
            )}
          >
            <span>{caption}</span>
            <ChevronDownIcon
              className={classNames(
                open ? "text-white" : "text-gray-300",
                "ml-2 h-5 w-5 group-hover:text-white"
              )}
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 mt-4 -ml-32 transform px-2 w-screen max-w-xs sm:px-0 lg:left-1/4">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-4 bg-gray-800 px-3 py-3 sm:gap-6 sm:p-6">
                  {dropdownItems.map(item => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-600"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-gray-400 stroke-1"
                        aria-hidden="true"
                      />
                      <div className="ml-4">
                        <p className="text-base font-extralight text-teal-200 mb-0">
                          {item.name}
                        </p>
                        <p className="mt-1 text-sm text-white mb-0">
                          {item.description}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default NavBarDropdown
