import React from "react"
import { useAuth } from "../../context/AuthContext"
import LoginForm from "../app/LoginForm"
import Footer from "./Footer"
import Navbar from "./Navbar"

const Layout = ({ children }) => {
  // container if screen size over medium
  const { isSignedIn } = useAuth()
  return (
    <div className="bg-gray-900">
      <div className="mx-0 md:mx-auto px-2 md:px-4 md:container">
        {isSignedIn ? (
          <div className="relative">
            <Navbar />
            {children}
            <Footer />
          </div>
        ) : (
          <LoginForm />
        )}
      </div>
    </div>
  )
}
//"relative overflow-hidden">
export default Layout
