import React, { Fragment } from "react"
import { Link } from "gatsby"

import { Popover, Transition } from "@headlessui/react"

import {
  AdjustmentsIcon,
  BeakerIcon,
  ChartPieIcon,
  ClipboardListIcon,
  CollectionIcon,
  CubeIcon,
  MailIcon,
  MenuIcon,
  NewspaperIcon,
  SearchIcon,
  ShieldCheckIcon,
  TrendingUpIcon,
  UserGroupIcon,
  XIcon,
} from "@heroicons/react/outline"
import NavBarDropdown from "./elements/NavBarDropdown"
import NavLogo from "./elements/NavLogo"

const fund = [
  {
    name: "Performance",
    description: "Most recent and historical performance for the strategy",
    href: "/fund/performance",
    icon: TrendingUpIcon,
  },
  {
    name: "Allocation",
    description: "Current and historical positioning",
    href: "/fund/allocation",
    icon: ChartPieIcon,
  },
  {
    name: "Fact sheets",
    description: "Check the latest fact sheets for the strategy",
    href: "/fund/factsheets",
    icon: NewspaperIcon,
  },
]
const strategy = [
  {
    name: "Investment philosophy",
    description: "Learn about the core underlying strategy",
    href: "/strategy/philosophy",
    icon: ClipboardListIcon,
  },
  {
    name: "Portfolio construction",
    description: "How to get from thousands of cryptos to a focussed portfolio",
    href: "/strategy/construction",
    icon: BeakerIcon,
  },
  {
    name: "Risk management",
    description: "How to trade-off between growth and capital protection",
    href: "/strategy/risk",
    icon: ShieldCheckIcon,
  },
]
const research = [
  {
    name: "Individual assets",
    description: "Deep dive into a specific curreny, token or any other asset",
    href: "/research/assets",
    icon: SearchIcon,
  },
  {
    name: "General market and sectors",
    description: "Top-down research on market segments",
    href: "/research/sectors",
    icon: CubeIcon,
  },
  {
    name: "Trading strategies",
    description: "Can we do better than HODLing?",
    href: "/research/trading",
    icon: AdjustmentsIcon,
  },
]
const team = [
  {
    name: "About",
    description: "Who we are and motivates us",
    href: "/team/about",
    icon: UserGroupIcon,
  },
  {
    name: "Blog",
    description:
      "General thinking and updates from us, basically anything NOT research",
    href: "/team/blog",
    icon: CollectionIcon,
  },
  {
    name: "Contact",
    description: "Please reach out!",
    href: "/team/contact",
    icon: MailIcon,
  },
]

const Navbar = () => {
  return (
    <Popover as="header" className="relative">
      <div className="mx-auto">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-auto lg:flex-2">
            <NavLogo size="3xl" />
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-gray-800 rounded-md p-2 mr-2 inline-flex items-center justify-center text-teal-200 hover:text-teal-300 hover:bg-gray-700">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden md:flex md:flex-1 justify-end space-x-10"
          >
            <NavBarDropdown caption="Fund" dropdownItems={fund} />
            <NavBarDropdown caption="Strategy" dropdownItems={strategy} />
            <NavBarDropdown caption="Research" dropdownItems={research} />
            <NavBarDropdown caption="Team" dropdownItems={team} />
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end">
            <Link
              to="/app/admin"
              className="ml-4 whitespace-nowrap inline-flex items-center justify-center px-6 py-2 rounded-md text-base font-extralight text-gray-900 bg-gradient-to-r from-teal-300 to-cyan-500 opacity-90 hover:opacity-100"
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-0 transition transform origin-top-right md:hidden z-10"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-gray-800 divide-y-2 divide-gray-50">
            <div className="flex flex-row pt-5 pb-6 px-2 w-full">
              <div className="flex flex-1 items-center pl-2">
                <NavLogo size="2xl" />
              </div>
              <div className="flex items-center justify-end">
                <div className="-mr-2">
                  <Popover.Button className="bg-teal-200  mr-2 rounded-md p-2 inline-flex items-center justify-center text-gray-800 hover:text-gray-600 hover:bg-teal-100 focus:outline-none ">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                <Link
                  to="/fund/performance"
                  className="text-base font-medium text-teal-50 hover:text-gray-700"
                >
                  Fund
                </Link>
                <Link
                  to="/strategy/construction"
                  className="text-base font-medium text-teal-50 hover:text-gray-700"
                >
                  Strategy
                </Link>
                <Link
                  to="/research/assets"
                  className="text-base font-medium text-teal-50 hover:text-gray-700"
                >
                  Research
                </Link>
                <Link
                  to="/team/contact"
                  className="text-base font-medium text-teal-50 hover:text-gray-700"
                >
                  {"Team & Contact"}
                </Link>
              </div>
              <div>
                <p className="mt-6 text-center text-base font-medium text-teal-50">
                  {"Existing investor? "}
                  <a
                    href="/app/admin"
                    className="text-cyan-600 hover:text-indigo-500"
                  >
                    Sign in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
export default Navbar
