import { auth } from "./fb"
import { signInWithEmailAndPassword, signOut } from "firebase/auth"

export const signIn = async ({ email, password }) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch {
    alert("Wrong login details!")
  }
  return
}
