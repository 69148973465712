import React from "react"
import { Formik, Field, Form } from "formik"
import { signIn } from "../../firebase/auth"

const LoginForm = () => {
  return (
    <div className="flex justify-center pt-40">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async values => signIn(values)}
      >
        <Form className="w-1/2 h-96 flex flex-col">
          <label htmlFor="email" className="m-2">
            Email
          </label>
          <Field
            id="email"
            name="email"
            type="email"
            className="bg-gray-100 border-2 mx-2"
          />
          <label htmlFor="password" className="m-2">
            Password
          </label>
          <Field
            id="password"
            name="password"
            className="bg-gray-100 border-2 mx-2"
          />
          <button
            type="submit"
            className="bg-gradient-to-r from-green-600 to-green-800 text-gray-100 w-20 self-center p-1 m-3"
          >
            Sign in
          </button>
        </Form>
      </Formik>
    </div>
  )
}

export default LoginForm
